<template>
  <div class='profile-details-main-wrapper'>
    <a-form>
      <a-row style='display: unset'>
        <a-col>
          <div class='profile-details-description'>Let's add your personal details</div>
          <div class='steps-count-text'>Step 5</div>
          <div class='profile-details-marital-status-wrapper'>
            <span class='profile-details-marital-status-text'
              >Marital Status</span
            >
            <a-radio-group
              v-model:value='modelRef.maritalStatus'
              name='radioGroup'
              :disabled='disable'
            >
              <a-radio
                :value='TYPE.MARITAL_STATUS.UNMARRIED'
                style='color: black; font-size: 14px; margin-right: 20px'
                >Single</a-radio
              >
              <a-radio :value='TYPE.MARITAL_STATUS.MARRIED' style='color: black; font-size: 14px'
                >Married</a-radio
              >
            </a-radio-group>
          </div>
          <div class='profile-details-inputs-wrapper'>
            <div style='width: 100%; margin-right: 10px'>
              <a-form-item v-bind='validateInfos.gender'>
                <a-select
                  v-model:value='modelRef.gender'
                  :size='size'
                  style='width: 100%'
                  class='select-field'
                  :options='genderOptions'
                  placeholder='Select your gender'
                  :disabled='disable'
                ></a-select>
              </a-form-item>
            </div>
            <div style='width: 100%'>
              <a-form-item v-bind='validateInfos.fatherName'>
                <a-input
                  class='input-field'
                  v-model:value='modelRef.fatherName'
                  placeholder='Enter your father’s first name'
                  :disabled='disable'
                >
                </a-input>
              </a-form-item>
            </div>
          </div>
          <div class='profile-details-inputs-wrapper'>
            <div style='width: 100%'>
              <a-form-item v-bind='validateInfos.annualIncome'>
                <a-select
                  v-model:value='modelRef.annualIncome'
                  :size='size'
                  style='width: 100%'
                  class='select-field'
                  :options='annualIncomeOptions'
                  placeholder='Select your annual income'
                  :disabled='disable'
                ></a-select>
              </a-form-item>
            </div>
          </div>
          <div class='profile-details-inputs-wrapper'>
            <div style='width: 100%; margin-right: 10px'>
              <a-form-item v-bind='validateInfos.occupation'>
                <a-select
                  v-model:value='modelRef.occupation'
                  :size='size'
                  style='width: 100%'
                  class='select-field'
                  :options='occupationOptions'
                  placeholder='Select your occupation'
                  :disabled='disable'
                ></a-select>
              </a-form-item>
            </div>
            <div style='width: 100%'>
              <a-form-item v-bind='validateInfos.tradingExperience'>
              <a-select
                v-model:value='modelRef.tradingExperience'
                :size='size'
                style='width: 100%'
                class='select-field'
                :options='tradingExperienceOptions'
                placeholder='Select your trading rxperience'
                :disabled='disable'
              ></a-select>
              </a-form-item>
            </div>
          </div>
          <div class='profile-details-continue-btn'>
            <a-button type='primary' @click='onClickContinue' :loading="loading">Save</a-button>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
// import { defineComponent } from '@vue/composition-api'
// import { useRouter } from 'vue-router';
import { onMounted, ref, reactive } from 'vue';
import { Form } from 'ant-design-vue';
import { useStore } from 'vuex';
import TYPE from '../../../constants/constant';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

const { useForm } = Form;

export default {
  components: {},
  setup() {
    const store = useStore();
    const maritalStatus = ref(TYPE.MARITAL_STATUS.UNMARRIED);
    const disable = ref(false);
    const loading = ref(false);
    const modelRef = reactive({
      gender: undefined,
      maritalStatus: maritalStatus.value,
      fatherName: '',
      annualIncome: undefined,
      occupation: undefined,
      tradingExperience: undefined,
    });

    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          if (response.data.data.prefill) {
            const preProfile = response.data.data.prefill;
            modelRef.gender = preProfile.gender ? preProfile.gender : undefined;
            modelRef.maritalStatus = preProfile.maritalStatus
              ? preProfile.maritalStatus : maritalStatus.value;
            modelRef.fatherName = preProfile.fatherName ? preProfile.fatherName : '';
            modelRef.annualIncome = preProfile.annualIncome ? preProfile.annualIncome : undefined;
            modelRef.occupation = preProfile.occupation ? preProfile.occupation : undefined;
            modelRef.tradingExperience = preProfile.tradingExperience
              ? preProfile.tradingExperience : undefined;
          }
        })
        .catch(() => {
        });
    });
    // const router = useRouter();
    const politicallyExposedPerson = ref('');
    const genderOptions = ref([
      {
        value: TYPE.GENDER.MALE,
        label: 'Male',
      },
      {
        value: TYPE.GENDER.FEMALE,
        label: 'Female',
      },
      {
        value: TYPE.GENDER.OTHER,
        label: 'Others',
      },
    ]);
    const annualIncomeOptions = ref([
      {
        value: TYPE.ANNUAL_INCOME['01'],
        label: 'BELOW 1 LAC',
      },
      {
        value: TYPE.ANNUAL_INCOME['02'],
        label: '1-5 LAC',
      },
      {
        value: TYPE.ANNUAL_INCOME['03'],
        label: '5-10 LAC',
      },
      {
        value: TYPE.ANNUAL_INCOME['04'],
        label: '10-25 LAC',
      },
      {
        value: TYPE.ANNUAL_INCOME['05'],
        label: '> 25 LAC',
      },
      {
        value: TYPE.ANNUAL_INCOME['06'],
        label: '25 LAC - 1 CR',
      },
      {
        value: TYPE.ANNUAL_INCOME['07'],
        label: '>1 CR',
      },
      {
        value: TYPE.ANNUAL_INCOME[10],
        label: 'UPTO RS.5,00,000',
      },
      {
        value: TYPE.ANNUAL_INCOME[11],
        label: 'RS.5,00,001 TO RS.25,00,000',
      },
      {
        value: TYPE.ANNUAL_INCOME[12],
        label: 'RS.5,00,00,001 AND ABOVE',
      },
      {
        value: TYPE.ANNUAL_INCOME[13],
        label: 'RS.25,00,001 TO RS.1,00,00,000',
      },
      {
        value: TYPE.ANNUAL_INCOME[14],
        label: 'RS.1,00,00,001 TO RS.5,00,00,000',
      },
      {
        value: TYPE.ANNUAL_INCOME[15],
        label: 'UPTO RS.50,00,000',
      },
      {
        value: TYPE.ANNUAL_INCOME[16],
        label: 'RS.50,00,001 TO RS.2,50,00,000',
      },
      {
        value: TYPE.ANNUAL_INCOME[17],
        label: 'RS.2,50,00,001 TO RS.10,00,00,000',
      },
      {
        value: TYPE.ANNUAL_INCOME[18],
        label: 'RS.10,00,00,001 TO RS.50,00,00,000',
      },
      {
        value: TYPE.ANNUAL_INCOME[19],
        label: 'RS.50,00,00,001 AND ABOVE',
      },
    ]);
    const occupationOptions = ref([
      {
        value: TYPE.PROFESSIONAL.GOVERNMENT_SERVICE,
        label: 'Government Service',
      },
      {
        value: TYPE.PROFESSIONAL.PUBLIC_SECTOR_OR_GOVERNMENT_SERVICE,
        label: 'Public Service / Government Service',
      },
      {
        value: TYPE.PROFESSIONAL.GOVERNMENT_SERVICE,
        label: 'Government Service',
      },
      {
        value: TYPE.PROFESSIONAL.OTHERS,
        label: 'Others',
      },
      {
        value: TYPE.PROFESSIONAL.PRIVATE_SECTOR_SERVICE,
        label: 'Private Sector Service',
      },
      {
        value: TYPE.PROFESSIONAL.PUBLIC_SECTOR,
        label: 'Public Sector',
      },
      {
        value: TYPE.PROFESSIONAL.BUSINESS,
        label: 'Business',
      },
      {
        value: TYPE.PROFESSIONAL.PROFESSIONAL,
        label: 'Professional',
      },
      {
        value: TYPE.PROFESSIONAL.AGRICULTURIST,
        label: 'Agriculturist',
      },
      {
        value: TYPE.PROFESSIONAL.RETIRED,
        label: 'Retired',
      },
      {
        value: TYPE.PROFESSIONAL.HOUSEWIFE,
        label: 'Housewife',
      },
      {
        value: TYPE.PROFESSIONAL.STUDENT,
        label: 'Student',
      },
      {
        value: TYPE.PROFESSIONAL.FOREX_DEALER,
        label: 'Forex Dealer',
      },
    ]);
    const tradingExperienceOptions = ref([
      {
        value: TYPE.TRADING_EXPERIENCE.ZERO_TO_ONE_YEAR,
        label: '0 - 1 Year',
      },
      {
        value: TYPE.TRADING_EXPERIENCE.ONE_TO_TWO_YEAR,
        label: '1 - 2 Years',
      },
      {
        value: TYPE.TRADING_EXPERIENCE.TWO_TO_FOUR_YEAR,
        label: '2 - 4 Years',
      },
      {
        value: TYPE.TRADING_EXPERIENCE.FOUR_TO_SIX_YEAR,
        label: '4 - 6 Years',
      },
      {
        value: TYPE.TRADING_EXPERIENCE.SIX_TO_TEN_YEAR,
        label: '6 - 10 Years',
      },
      {
        value: TYPE.TRADING_EXPERIENCE.MORE_THAN_TEN_YEAR,
        label: 'More than 10 Years',
      },
    ]);

    const rulesRef = reactive({
      gender: [
        {
          required: true,
          message: 'Select your gender',
        },
      ],
      fatherName: [
        {
          required: true,
          message: 'Enter your father’s first name',
        },
      ],
      annualIncome: [
        {
          required: true,
          message: 'Select your annual income',
        },
      ],
      occupation: [
        {
          required: true,
          message: 'Select your occupation',
        },
      ],
      tradingExperience: [
        {
          required: true,
          message: 'Select your trading rxperience',
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef,
    );

    const onClickContinue = () => {
      validate().then(() => {
        loading.value = true;
        disable.value = true;
        services
          .addPersonalDetails(modelRef)
          .then((response) => {
            loading.value = false;
            store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          })
          .catch((error) => {
            loading.value = false;
            disable.value = false;
            const systemError = Operations.axiosErrorHandler(error);
            const message = systemError.status;
            const description = systemError.message
              ? systemError.message
              : '';
            Operations.notificationError(message, description);
          });
      });
    };

    return {
      onClickContinue,
      maritalStatus,
      politicallyExposedPerson,
      validateInfos,
      resetFields,
      modelRef,
      genderOptions,
      annualIncomeOptions,
      occupationOptions,
      tradingExperienceOptions,
      TYPE,
      disable,
      loading,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/profileDetails.scss';
</style>
